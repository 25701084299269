import React from 'react';
import InlineSVG from 'svg-inline-react';
import ButtonLink from '../ButtonLink';
import { getUserObject } from '../../services/auth';

const svgSmile = '<svg enable-background="new 0 0 279.97 70.654" viewBox="0 0 279.97 70.654" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="a" gradientTransform="matrix(-129.743 140.0852 -150.0412 -138.9639 -72575.7656 74145.1172)" gradientUnits="userSpaceOnUse" x1="-543.9473" x2="-542.9473" y1="-14.7129" y2="-14.7129"><stop offset="0" stop-color="#fca32d"/><stop offset="1" stop-color="#ff6196"/></linearGradient><path d="m260.963 60.927c3.349 3.105 6.551 6.304 9.607 9.598l9.247-9.985c-3.085-3.288-6.297-6.478-9.634-9.569-.076-.07-.152-.141-.228-.211-77.361-71.532-198.065-66.807-269.598 10.554l9.955 9.22c66.456-71.753 178.898-76.062 250.651-9.607z" fill="url(#a)"/></svg>';


const FacaParte = () => {

  const authUser = getUserObject();


  const leftBubbles = []
  let leftSpacing = 60;
  for(let i = 1; i <= 6; i++) {
    leftBubbles.push(
      <img
        className="-elmt"
        src={require(`../../assets/images/temp/temp-persona-${i}.jpg`)}
        alt="ContaComigo"
        key={`bubble-${i}`}
        style={{marginLeft: `${leftSpacing}px`}}
      />
    );
    leftSpacing = leftSpacing - 50 > 0 ? leftSpacing - 50 : leftSpacing + 50;
  }
  const rightBubbles = []
  let rightSpacing = 10;
  for(let i = 7; i <= 12; i++) {
    rightBubbles.push(
      <img
        className="-elmt"
        src={require(`../../assets/images/temp/temp-persona-${i}.jpg`)}
        alt="ContaComigo"
        key={`bubble-${i}`}
        style={{marginLeft: `${rightSpacing}px`}}
      />
    );
    rightSpacing = rightSpacing - 50 > 0 ? rightSpacing - 50 : rightSpacing + 50;
  }

  return (
    <>
    <section className="section -cover b-3" name="facaparte">
      <div className="container section -holder">

        <div className="facaparte -bg">

          <div className="facaparte -bubbles -col -left">
            {leftBubbles}
          </div>
          <div className="facaparte -bubbles -col -right">
            {rightBubbles}
          </div>

        </div>

        <div className="facaparte -main">
          <div className="facaparte -video-container">
            <video width="100%" height="100%" controls >
              <source src={require('../../assets/videos/anim.mp4')} type="video/mp4"/>
              Seu browser não suporta a tag video.
            </video>
            <div className="smile smile-2 -wrap" style={{ opacity:'0'}}>
              <div className="smile -element">
                <div className="smile -circle">
                  <InlineSVG src={svgSmile} />
                </div>
              </div>
            </div>
            <div className="smile smile-4 -wrap" style={{ opacity:'0'}}>
              <div className="smile -element">
                <div className="smile -circle">
                  <InlineSVG src={svgSmile} />
                </div>
              </div>
            </div>
          </div>

          <div className="facaparte -text">
            <h2 className="title -larger">
              Fazer o cadastro no ContaComigo
              <br/>
              é muito simples.
            </h2>
            <p className="text -median">
              Faça parte dessa rede de troca e solidariedade.
              <br/>
              Você pode trocar serviços e produtos, ajudando a sua família e muitas outras.
            </p>
          </div>

          {!authUser &&
            <div className="facaparte -cta">
              <ButtonLink title="Quero fazer meu cadastro já" className="js-anime -anime -anime-3 -motion" href="/cadastro" />
            </div>
          }
        </div>
      </div>
    </section>
    </>
  );
}

export default FacaParte;
