import React, { useEffect, useState } from "react";
import InlineSVG from 'svg-inline-react';
import useIntersectObserver from '../../constants/useIntersectObserver';
import ButtonScroll from '../ButtonScroll';

const svgSmile = '<svg enable-background="new 0 0 279.97 70.654" viewBox="0 0 279.97 70.654" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="a" gradientTransform="matrix(-129.743 140.0852 -150.0412 -138.9639 -72575.7656 74145.1172)" gradientUnits="userSpaceOnUse" x1="-543.9473" x2="-542.9473" y1="-14.7129" y2="-14.7129"><stop offset="0" stop-color="#fca32d"/><stop offset="1" stop-color="#ff6196"/></linearGradient><path d="m260.963 60.927c3.349 3.105 6.551 6.304 9.607 9.598l9.247-9.985c-3.085-3.288-6.297-6.478-9.634-9.569-.076-.07-.152-.141-.228-.211-77.361-71.532-198.065-66.807-269.598 10.554l9.955 9.22c66.456-71.753 178.898-76.062 250.651-9.607z" fill="url(#a)"/></svg>';
const mosaic = [
  {
    id: '1',
    url: 'temp-foto-hero-1.jpg',
    urlMob: 'temp-foto-hero-mob-1.jpg',
    title: 'Mulher sorrindo',
  },
  {
    id: '2',
    url: 'temp-foto-hero-2.jpg',
    urlMob: 'temp-foto-hero-mob-2.jpg',
    title: 'Senhor fazendo joinha',
  },
  {
    id: '3',
    url: 'temp-foto-hero-3.jpg',
    urlMob: 'temp-foto-hero-mob-3.jpg',
    title: 'Senhora sorrindo com mão no rosto',
  },
];

const Hero = () => {
  /**
   * Animações dos elementos do Hero
   */
  const [data, setData] = useState([]);
  const [observer, setElements, entries] = useIntersectObserver({
    threshold: 0.1,
    root: null
  });

  useEffect(() => {
    setData(mosaic);
  }, []);

  useEffect(() => {
    if (data.length) {
      let elements = Array.from(document.getElementsByClassName('js-anime'));
      setElements(elements);
    }
  }, [data, setElements]);

  const images = data.map(image => (
    <div
      className={`mosaic -pic -pic-${image.id} js-anime -motion`}
      key={image.id}
      style={{ opacity:'0'}}
      >
      <picture>
        <source
          media="(max-width: 991px)"
          srcSet={require('../../assets/images/temp/' + image.urlMob)}
          type={image.title} />
        <source
          media="(min-width: 992px)"
          srcSet={require('../../assets/images/temp/' + image.url)}
          type={image.title} />
        <img
          src={require('../../assets/images/temp/' + image.urlMob)}
          alt={image.title} />
      </picture>
    </div>
  ));

  useEffect(() => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTimeout(function() {
          entry.target.style.opacity = '1';
          entry.target.classList.remove('-motion');
          observer.unobserve(entry.target);
        }, 500);
      }
    });
  }, [entries, observer]);

  return (
    <section className="hero section -cover b-support">
      <div className="container section -holder">
        <div className="grid -wrap -row-6-1-5 -center">
          <article className="hero -image grid -col-a">
            <video width="100%" height="100%" controls >
              <source src={require('../../assets/videos/ccap.mp4')} type="video/mp4"/>
              Seu browser não suporta a tag video.
            </video>
          </article>
          <article className="hero -content grid -col-b flex -column">
            <h1 className="title -larger c-inverse mt-0 mt-md-4 js-anime -anime -anime-1 -motion" style={{ opacity:'0'}}>
              Você é projetista <br />
              e <span><u>precisa de pilates?</u></span>
            </h1>
            <p className="text -larger c-inverse mt-2 mt-lg-4 js-anime -anime -anime-2 -motion" style={{ opacity:'0'}}>
            No ContaComigo, você encontra pessoas para trocar seus serviços e produtos, com ou sem dinheiro. Faça parte dessa rede de troca e solidariedade.
            </p>
            <ButtonScroll title="Quero saber mais" className="js-anime -anime -anime-3 -motion" />
          </article>
        </div>
      </div>
    </section>
  );
};

export default Hero;
