import React, { useEffect, useState } from "react";
import { Controller } from 'react-hook-form';
import MaskedInput from "react-input-mask";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useHistory } from "react-router-dom";
import api from '../../services/api';
import { updateUserInfo } from '../../services/user';
import InlineSVG from 'svg-inline-react';
import SuccessEdit from './SuccessEdit';
import cep from 'cep-promise'

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const ProfileStep2 = ({userData, profissaoList, areaList, formObject, stepStatus, setStepFinished, checkSign, stepFinished}) => {


  const history = useHistory();

  const [forceShowForm, setForceShowForm] = useState();
  const [formIsInitialized, setFormIsInitialized] = useState(false);
  const [cepIsInitiall, setCepIsInitial] = useState(true);

  const [filteredProfissaoList, setFilteredProfissaoList] = useState([]);
  const [initialProfissao, setInitialProfissao] = useState();
  const [initialArea, setInitialArea] = useState();

  const [areaAtuacaoInputValue, setAreaAtuacaoInputValue] = useState();
  const [profissaoInputValue, setProfissaoInputValue] = useState();

  const handleAreaChange = async (event) => {
    setValue2('areaAtuacao', event.value);
    setAreaAtuacaoInputValue(event.value);
    setFilteredProfissaoList(profissaoList.filter(profissao => profissao?.area === null || profissao?.area === event.value));
  };

  const handleProfissaoChange = (event) => {
    setValue2('profissao', event.value);
    setProfissaoInputValue(event.value);
  };

  const validateYoutubeUrl = (val) => {
    return (val.length === 0 || val.includes('youtube.com') || val.includes('youtu.be'))
  }

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    errors: errors2,
    setValue: setValue2,
    reset: resetForm,
    control,
    triggerValidation,
    watch: watch2
  } = formObject;


  const profilePicStatus = watch2('profilePicture', null);
  const cepStatus = watch2('addressCep', null);
  const areaAtuacaoStatus = watch2('areaAtuacao', null);


  useEffect(() => {

    const updateAddress = (data) => {
      setValue2('addressMain', `${data.street}, ___ - ${data.neighborhood}`);
      setValue2('addressCity', data.city);
      setValue2('addressState', data.state);
    }

    if(cepStatus && (cepStatus.replace(/\D/g,'') !== userData.addressCep || !cepIsInitiall)) {

      const cepValue = cepStatus.replace(/\D/g,'');
      if(cepValue.length === 8) {
        cep(cepValue)
          .then(updateAddress)
          .catch(console.log);
        setCepIsInitial(false);
      }
    }

  }, [cepStatus, setValue2, userData.addressCep, cepIsInitiall, setCepIsInitial, areaAtuacaoStatus]);

  useEffect(() => {
    if(userData && !formIsInitialized) {
      let thisCep = userData.addressCep || '';
      if(thisCep){
        thisCep = `${thisCep.slice(0, 5)}-${thisCep.slice(5, 9)}`
      }
      userData.parsedBirthDate();


      const filterOptions = (areaId) => profissaoList.filter(profissao => profissao?.area === null || profissao?.area === areaId);

      if(userData.profissao.id) {
        let profissaoArray = filterOptions(userData.areaAtuacao.id);
        const userProfissaoObject = {
          value: userData.profissao.id,
          label: userData.profissao.name,
          area: null
        };
        profissaoArray.unshift(userProfissaoObject);
        setFilteredProfissaoList(profissaoArray);
        setInitialProfissao(userProfissaoObject);
      }
      else{
        setFilteredProfissaoList([]);
      }

      if(userData.areaAtuacao.id) {
        const thisUserArea = {
          value: userData.areaAtuacao.id,
          label: userData.areaAtuacao.name
        };
        setInitialArea(thisUserArea);
      }

      resetForm({
        birthDate: userData.birthDate || '',
        addressCep: thisCep,
        addressMain: userData.addressMain || '',
        addressCity: userData.addressCity || '',
        areaAtuacao: userData.areaAtuacao.id || '',
        profissao: userData.profissao.id || '',
        addressState: userData.addressState || '',
        youtubeVideo: userData.youtubeVideo || '',
        aboutSelf: userData.aboutSelf || '',
        education: userData.education || ''
      });
      setValue2('addressCep', thisCep);
      triggerValidation('birthDate');

      setFormIsInitialized(true);
    }
  }, [userData, resetForm, setValue2, triggerValidation, profissaoList, areaList, formIsInitialized]);

  const onSubmit = async(data) => {
    let {
      birthDate,
      addressCep,
      addressMain,
      addressCity,
      addressState,
      youtubeVideo,
      areaAtuacao,
      profissao,
      aboutSelf,
      education,

      } = data;

      try {
        const splitBirthDate = await birthDate.split('/');
        birthDate = `${splitBirthDate[2]}-${splitBirthDate[1]}-${splitBirthDate[0]}`;

        const profissaoId = isNaN(profissaoInputValue || profissao) ? null : profissaoInputValue || profissao;
        const profissaoName = !isNaN(profissaoInputValue || profissao) ? 'Outra profissão' : profissaoInputValue || profissao;

        // Form data
        const formData = new FormData();
        const imageFile = document.querySelector('input#profilePictureInput');

        // Appending keys and values
        formData.append('step', 2);
        if(imageFile){
          formData.append('profilePicture', imageFile.files[0]);
        }
        formData.append('birthDate', birthDate);
        formData.append('areaAtuacaoId', areaAtuacaoInputValue || areaAtuacao);
        formData.append('profissaoId', profissaoId || profissao);
        formData.append('profissaoName', profissaoName);
        formData.append('addressCep', addressCep.replace(/\D/g,''));
        formData.append('addressMain', addressMain);
        formData.append('addressCity', addressCity);
        formData.append('addressState', addressState);
        formData.append('youtubeVideo', youtubeVideo);
        formData.append('aboutSelf', aboutSelf);
        formData.append('education', education);

        const result = await api.post('/user-details', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        await updateUserInfo(result.data);
        setStepFinished(true);
        setForceShowForm(false);
        history.push('/meu-perfil');
      } catch(err){
        setStepFinished(false);
        setForceShowForm(false);
        window.alert('Erro interno do servidor: ' + err);
      }
  };

  return (
      <>
        <AccordionItem className="windbox -item">
          <AccordionItemHeading className="windbox -head">
            <AccordionItemButton className="windbox -button">
              <div className="windbox -button-target">
                {stepStatus['2'] &&
                  <InlineSVG src={checkSign} className="windbox -position -finished"/>
                }
                {!stepStatus['2'] &&
                  <span className="windbox -position">2</span>
                }
                <h3 className="windbox -title">Minhas informações</h3>
              </div>
              <span className="windbox -icon">
                <i className="icon"></i>
                <span className="sr-only">abrir</span>
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="windbox -panel">
            <div className="windbox -content">
              <form onSubmit={ handleSubmit2(onSubmit)} noValidate className={`${!stepFinished || forceShowForm ? '' : 'form -hide'}`}>
                <div className={`form -field ${errors2.birthDate ? '-error' : ''}`}>
                  <label htmlFor="birthDate">
                    Qual sua data de nascimento?
                  </label>
                  <div className="form -group -gray">
                    <Controller
                      as={MaskedInput}
                      control={control}
                      name="birthDate"
                      mask="99/99/9999"
                      placeholder="DD/MM/AAAA"
                      defaultValue={userData.birthDate}
                      rules={{
                        required: true,
                        validate: value => value.replace(/\D/g,'').length === 8
                      }}
                    />
                    { errors2.birthDate &&
                      <p className="form -message -error">Por favor, digite uma data de nascimento válida</p>
                    }
                  </div>
                </div>
                <div className="form -field -field-1">
                  <label htmlFor="profissao">
                    Qual sua área de atuação?
                  </label>
                  <div className="form -group -gray">
                    {userData.areaAtuacao.id && initialArea &&
                      <Select
                        name="areaAtuacao"
                        id="areaAtuacao"
                        className="form -select"
                        classNamePrefix="form -select-item"
                        options={areaList}
                        onChange={(event) => handleAreaChange(event)}
                        defaultValue={initialArea}
                        ref={e => register2({
                          name: 'areaAtuacao',
                          required: true
                        })}
                      >
                      </Select>
                    }
                    {!userData.areaAtuacao.id &&
                      <Select
                        name="areaAtuacao"
                        id="areaAtuacao"
                        className="form -select"
                        classNamePrefix="form -select-item"
                        options={areaList}
                        onChange={(event) => handleAreaChange(event)}
                        ref={e => register2({
                          name: 'areaAtuacao',
                          required: true
                        })}
                      >
                      </Select>
                    }
                    { errors2.areaAtuacao &&
                      <p className="form -message -error">Por favor, selecione uma área de atuação</p>
                    }
                  </div>
                </div>
                <div className="form -field -field-2">
                  <label htmlFor="profissao">
                    Qual sua profissão?
                  </label>
                  <div className="form -group -gray">
                    {userData.profissao.id && initialProfissao &&
                      <CreatableSelect
                        id="profissao"
                        name="profissao"
                        onChange={(event) => handleProfissaoChange(event)}
                        options={filteredProfissaoList}
                        className="form -select"
                        classNamePrefix="form -select-item"
                        defaultValue={initialProfissao}
                        ref={e => register2({
                          name: 'profissao',
                          required: true
                        })}
                      >
                      </CreatableSelect>
                    }
                    {!userData.profissao.id &&
                      <CreatableSelect
                        id="profissao"
                        name="profissao"
                        onChange={(event) => handleProfissaoChange(event)}
                        options={filteredProfissaoList}
                        className="form -select"
                        classNamePrefix="form -select-item"
                        ref={e => register2({
                          name: 'profissao',
                          required: true
                        })}
                      >
                      </CreatableSelect>
                    }
                    { errors2.profissao &&
                      <p className="form -message -error">Por favor, selecione uma profissão</p>
                    }
                  </div>
                </div>
                <div className={`form -field mb-0 ${errors2.addressCep ? '-error' : ''}`}>
                  <label htmlFor="addressCep">
                    CEP
                  </label>
                  <div className="form -group -gray">
                    <Controller
                      as={MaskedInput}
                      control={control}
                      name="addressCep"
                      mask="99999-999"
                      placeholder="_____-___"
                      defaultValue={userData.addressCep}
                      rules={{
                        required: true,
                        validate: value => value.replace(/\D/g,'').length === 8
                      }}
                    />
                    { errors2.addressCep &&
                      <p className="form -message -error">Por favor, insira seu CEP</p>
                    }
                  </div>
                </div>
                <div className={`form -field mb-0 mt-1 ${errors2.addressMain ? '-error' : ''}`}>
                  <div className="form -group -gray">
                    <input
                      type="text"
                      id="addressMain"
                      name="addressMain"
                      autoComplete="off"
                      placeholder="Endereço"
                      ref={register2({
                        required: true,
                        maxLength: 120
                      })}
                    />
                    { errors2.addressMain &&
                      <p className="form -message -error">Por favor, insira seu endereço</p>
                    }
                  </div>
                </div>
                <div className={`form -field mb-0 mt-1 ${errors2.addressCity ? '-error' : ''}`}>
                  <div className="form -group -gray mt-1">
                    <input
                      type="text"
                      id="addressCity"
                      name="addressCity"
                      autoComplete="off"
                      placeholder="Cidade"
                      ref={register2({
                        required: true,
                        maxLength: 120
                      })}
                    />
                    { errors2.addressCity &&
                      <p className="form -message -error">Por favor, insira sua cidade</p>
                    }
                  </div>
                </div>
                <div className={`form -field mt-1 ${errors2.addressState ? '-error' : ''}`}>
                  <div className="form -group -gray mt-1">
                    <input
                      type="text"
                      id="addressState"
                      name="addressState"
                      autoComplete="off"
                      placeholder="Estado"
                      maxLength="2"
                      autoCapitalize="on"
                      ref={register2({
                        required: true,
                        maxLength: 2,
                        minLength: 2
                      })}
                    />
                    { errors2.addressState &&
                      <p className="form -message -error">Por favor, insira o nome (abreviado) do seu estado</p>
                    }
                  </div>
                </div>
                <div className={`form -field mt-1 ${errors2.youtubeVideo ? '-error' : ''}`}>
                  <label htmlFor="youtubeVideo">
                    Manda um vídeo pra gente falando mais de você
                  </label>
                  <div className="form -group -gray">
                    <input
                      type="text"
                      id="youtubeVideo"
                      name="youtubeVideo"
                      autoComplete="off"
                      placeholder="Cole aqui o endereço do YouTube"
                      ref={register2({
                        required: false,
                        validate: validateYoutubeUrl
                      })}
                    />
                    { errors2.youtubeVideo &&
                      <p className="form -message -error">O link para o vídeo precisa ser um link completo do youtube</p>
                    }
                  </div>
                </div>
                <div className="form -field">
                  <label htmlFor="aboutSelf">
                    Conte um pouco mais sobre você
                  </label>
                  <div className="form -group -gray">
                    <textarea
                      id="aboutSelf"
                      name="aboutSelf"
                      autoComplete="off"
                      placeholder="Aproveite esse espaço para falar um pouco mais sobre suas habilidades, formação e tempo disponível para oferecer seus produtos ou serviços, por exemplo. Mostre para as pessoas como elas podem contar com você. ;)"
                      onFocus={ (e) => e.target.placeholder = "" }
                      onBlur={(e) => e.target.placeholder = "Aproveite esse espaço para falar um pouco mais sobre suas habilidades, formação e tempo disponível para oferecer seus produtos ou serviços, por exemplo. Mostre para as pessoas como elas podem contar com você. ;)" }
                      ref={register2({
                        required: true,
                        maxLength: 480
                      })}
                    />
                    { errors2.aboutSelf &&
                      <p className="form -message -error">Você precisa inserir uma descrição sobre você</p>
                    }
                  </div>
                </div>
                <div className="form -field">
                  <label htmlFor="education">
                    Quer acrescentar algum curso?
                  </label>
                  <div className="form -group -gray">
                    <input
                      type="text"
                      id="education"
                      name="education"
                      autoComplete="off"
                      placeholder="Escreva aqui"
                      ref={register2({
                        maxLength: 240
                      })}
                    />
                  </div>
                </div>

                {!userData.profilePicture &&
                  <div className="form -field">
                    <label htmlFor="profilePicture">
                      Agora, seria muito legar ter uma foto sua. Vamos lá?
                    </label>
                    <div className="form -group -gray">
                      <div className="form -file">
                        <input
                          type="file"
                          id="profilePictureInput"
                          name="profilePicture"
                          autoComplete="off"
                          placeholder="Anexar arquivo"
                          ref={register2({
                            required: true
                          })}
                        />
                        <div className={`form -file-inner ${(profilePicStatus?.length > 0) ? '-ok' : ''}`}>
                          <span>{(profilePicStatus?.length > 0) ? 'Foto anexada :)' : 'Anexar foto'}</span>
                          <i>
                            {(profilePicStatus?.length === 0 || !profilePicStatus?.length) &&
                              <svg viewBox="0 0 30 23.461" xmlns="http://www.w3.org/2000/svg"><path d="m30.573 13.419h-4.373l-1.6-2.948a1.062 1.062 0 0 0 -.935-.531h-9.808a1.062 1.062 0 0 0 -.935.531l-1.593 2.948h-4.382a3.187 3.187 0 0 0 -3.187 3.187v13.608a3.187 3.187 0 0 0 3.187 3.186h23.626a3.187 3.187 0 0 0 3.187-3.187v-13.607a3.187 3.187 0 0 0 -3.187-3.187zm-11.813 16.264a7.006 7.006 0 1 1 7.006-7.006 7.006 7.006 0 0 1 -7.006 7.006zm4.881-7.006a4.881 4.881 0 1 1 -4.881-4.877 4.881 4.881 0 0 1 4.881 4.877z" transform="translate(-3.76 -9.94)" fill="#363636"/></svg>
                            }
                            {(profilePicStatus?.length > 0) &&
                              <svg xmlns="http://www.w3.org/2000/svg" width="15.12" height="12" viewBox="0 0 15.12 12">
                                <g id="Grupo_70" data-name="Grupo 70" transform="translate(-1125.586 -237.343)">
                                  <rect id="Rectangle" width="7.92" height="3.394" rx="1" transform="translate(1127.986 241.343) rotate(45)" fill="#fff"/>
                                  <rect id="Rectangle-2" data-name="Rectangle" width="13.576" height="3.394" rx="1" transform="translate(1140.705 239.743) rotate(135)" fill="#fff"/>
                                </g>
                              </svg>
                            }
                          </i>
                        </div>
                      </div>
                      { errors2.profilePicture &&
                        <p className="form -message -error">Você precisa enviar uma foto de perfil</p>
                      }
                    </div>
                  </div>
                }

                <div className="form -field center mt-4">
                  <button type="submit" className="button -register -median">
                    <span className="button -text">
                      Salvar e continuar
                    </span>
                  </button>
                </div>
              </form>
              {stepFinished && !forceShowForm &&
                <SuccessEdit setForceShow={setForceShowForm} />
              }
            </div>
          </AccordionItemPanel>
        </AccordionItem>
    </>
  );
}

export default ProfileStep2;
